import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import HeaderTypography from "../components/v2/Typography/HeaderTypography";
import ParagraphTypography from "../components/v2/Typography/ParagraphTypography";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";
import { makeStyles } from "@material-ui/core/styles";
import WebformBanner from "../images/v2/webform/WebformBanner.svg";
import YouDontHaveCoderto from "../images/v2/webform/YouDontHaveCoderto.svg";
import DontMissOutAnyLeads from "../images/v2/webform/DontMissOutAnyLeads.svg";
import CaptureLeadsFromanyPlatform from "../images/v2/webform/CaptureLeadsFromanyPlatform.svg";
import Squarespace from "../images/v2/webform/Squarespace.svg";
import Weebly from "../images/v2/webform/Weebly.svg";
import Wordpress from "../images/v2/webform/Wordpress.svg";
import DesignTheWayYouWant from "../images/v2/webform/DesignTheWayYouWant.svg";
import AnalyzeToImproveYourLeadGeneration from "../images/v2/webform/AnalyzeToImproveYourLeadGeneration.svg";
import Automate from "../images/v2/webform/Automate.svg";
import Communicate from "../images/v2/webform/Communicate.svg";
import Conduct from "../images/v2/webform/Conduct.svg";
import WhiteButton from "../components/v2/WhightButton";
import requestDemoBGImg from "../images/v2/request-demo-bg.svg";
import testimonialBgImg from "../images/v2/testimonial-bg.svg";
import useWidth from "../hooks/useWidth";
import SEO from "../components/SEO";
import { Link } from "gatsby";
import SingleTestimonial from "../components/v2/HomeSlider/SingleTestimonial";

const useStyles = makeStyles((theme) => ({
  bgRoot: {
    backgroundImage:
      "linear-gradient(to bottom, #e7f2fe, rgba(231, 242, 254, 0))",
  },
  img: {
    maxWidth: "100%",
    height: "auto",
  },
  btn: {
    // minWidth: 307,
    // minHeight: 52,
    // fontWeight: 600,
    // fontSize: 20,
  },
  requestDemoRoot: {
    background: `url(${requestDemoBGImg})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  testimonialCustomerImg: {
    maxWidth: "90%",
    width: "290px",
    borderRadius: "10px",
    boxShadow: "0 15px 50px 0 rgba(0, 0, 0, 0.16)",
    marginTop: "2.65rem",
    marginLeft: "3rem",
  },
  testimonialBg: {
    background: `url(${testimonialBgImg}) no-repeat`,
    backgroundSize: "195px 223px",
  },
  featureItem: {
    borderLeft: "1px solid",
    borderRadius: theme.shape.borderRadius * 2.5,
    borderColor: "#e2f0ff",
    padding: theme.spacing(4),
    [theme.breakpoints.down("sm")]: {
      borderLeft: "unset",
    },
  },
}));

const client = {
  image: (
    <StaticImage
      alt="Pepper Cloud + Mustard Seed System Corporation"
      layout="constrained"
      src="../images/v2/testimonials-images/judith-adao-peppercloud-customer.png"
    />
  ),
  name: "Judith M. Adao",
  position: "General Manager",
  company: "Mustard Seed Systems Corporation, Philippines",
  contentSmall: `“Pepper Cloud CRM is a must for every growing sales organization. Very simple, yet it gives all the information needed to manage the sales leads.”`,
  content: (
    <React.Fragment>
      Pepper Cloud is a simple and easy to use CRM tool and it gives all the
      information needed to manage the sales leads. The CRM also helps us to run
      data driven marketing campaigns.
      <br />
      <br />
      {/* eslint-disable-next-line react/no-unescaped-entities */}
      Pepper Cloud's customer service and local support are incredible and
      helped us at every stage of the implementation. This CRM is a must for
      every growing sales organization.
    </React.Fragment>
  ),
};

const Webform = () => {
  const width = useWidth();
  const classes = useStyles();
  return (
    <React.Fragment>
      <SEO
        canonical="/lead-capture-generation-webform"
        description="Description - Convert your website visitors into qualified leads with Pepper Cloud web forms. Build powerful online forms for lead capture and lead generation"
        keywords="lead capture form,Lead Capture Form,Lead generation ,Lead generation form ,Online webform ,Lead capture,Online form"
        ogData={{
          "og:image": [
            "/meta/og/1X1/web-form.png",
            "/meta/og/1024X512/web-form.png",
            "/meta/og/1200X630/web-form.png",
          ],
        }}
        pathname="/lead-capture-generation-webform"
        title="Web forms for lead capture and generation | Pepper Cloud CRM"
      />
      <Container>
        <Box
          alignItems="center"
          component={Grid}
          container
          justify="center"
          textAlign={{ sm: "center", xs: "center" }[width] || "left"}
        >
          <Grid item md={6} sm={12}>
            <HeaderTypography
              component="h1"
              mb={4}
              mt={{ sm: 6, xs: 5 }[width] || 20}
            >
              Transform your visitors into leads with secure web forms
            </HeaderTypography>
            <ParagraphTypography mb={6}>
              Accelerate your sales process by capturing leads directly from
              your website and social media channels to your CRM system. With
              intuitive and highly customisable web forms, streamline your lead
              generation process and auto-assign the leads to the right
              salesperson in no time.
            </ParagraphTypography>
            <Button
              className={classes.btn}
              color="secondary"
              component={Link}
              size="large"
              to="/contact"
              variant="contained"
            >
              Show me
            </Button>
            <Box mb={{ md: 14 }} />
          </Grid>
          <Grid item md={6} sm={12}>
            <Box textAlign="center">
              <img
                alt="Sync your webforms in your homepage, social media platforms."
                className={classes.img}
                src={WebformBanner}
              />
            </Box>
          </Grid>
        </Box>
      </Container>
      <Divider light />

      <div className={classes.bgRoot}>
        <Container>
          <Box
            alignItems="center"
            display="flex"
            flexDirection="column"
            mb={{ md: 4 }}
            pt={{ md: 6 }}
          >
            <HeaderTypography
              component="h2"
              maxWidth={726}
              mb={4}
              mt={4}
              textAlign="center"
            >
              Build code-free web forms
            </HeaderTypography>

            <ParagraphTypography maxWidth={860} m={0} textAlign="center">
              Build web forms without writing even a single line of code. With a
              simple drag-and-drop option in the web form builder, create and
              customise them from scratch and fit into all your lead capture
              needs. Get a live preview of the form as you design it, and adjust
              it as per your requirements.
            </ParagraphTypography>
          </Box>
          <img
            alt="Customisable lead capture web form"
            className={classes.img}
            src={YouDontHaveCoderto}
          />
        </Container>

        <Box my={{ md: 10 }}>
          <Divider light />
        </Box>

        <Container>
          <Grid container spacing={2}>
            <Grid item md={6} sm={12}>
              <HeaderTypography
                component="h2"
                my={4}
                textAlign={{ sm: "center", xs: "center" }[width] || "left"}
              >
                Capture high-intent leads
              </HeaderTypography>
              <ParagraphTypography
                textAlign={{ sm: "center", xs: "center" }[width] || "left"}
              >
                Don’t miss out on high-intent leads from your website through
                the online form, store them in your{" "}
                <a href="https://peppercloud.com/"> sales CRM software </a>, and
                engage with them right away. Build the conversations around the
                context, personalise the way you build customer relationships
                and close deals faster.
              </ParagraphTypography>
            </Grid>

            <Grid item md={6} sm={12}>
              <img
                alt="Customisable web forms to capture website visitor information"
                className={classes.img}
                src={DontMissOutAnyLeads}
              />
            </Grid>
          </Grid>
        </Container>
      </div>

      <Box my={{ md: 10 }}>
        <Divider light />
      </Box>

      <Container>
        <Grid
          container
          direction={{ sm: "column-reverse", xs: "column-reverse" }[width]}
          spacing={2}
        >
          <Grid item md={6} sm={12}>
            <img
              alt="secure web form link"
              className={classes.img}
              src={CaptureLeadsFromanyPlatform}
            />
          </Grid>

          <Grid item md={6} sm={12}>
            <HeaderTypography
              component="h2"
              my={4}
              textAlign={{ sm: "center", xs: "center" }[width] || "left"}
            >
              Secure leads from any platform
            </HeaderTypography>
            <ParagraphTypography
              textAlign={{ sm: "center", xs: "center" }[width] || "left"}
            >
              Let the leads roll into your{" "}
              <a href="https://peppercloud.com/sales-pipeline-management-tool">
                sales pipeline
              </a>
              . Share the web forms as standalone web pages with your customers
              or integrate them within your website, WordPress, Google Sites, or
              any Social media pages. Set them live to capture your{" "}
              <a href="https://blog.peppercloud.com/an-experts-guide-to-inbound-and-outbound-sales/">
                inbound leads
              </a>
              .
            </ParagraphTypography>
            <br />
            <Box textAlign={{ sm: "center", xs: "center" }[width] || "left"}>
              <Button
                className={classes.btn}
                color="secondary"
                component={Link}
                size="large"
                to="/contact"
                variant="contained"
              >
                Let my leads roll in
              </Button>
            </Box>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item md={6} sm={12}>
            <Grid container spacing={4}>
              <Grid item xs={4}>
                <Box
                  alignItems="center"
                  display="flex"
                  height="100%"
                  justifyContent="center"
                >
                  <Box
                    alt="squarespace+peppercloud"
                    className={classes.img}
                    component="img"
                    height="80px !important"
                    src={Squarespace}
                  />
                </Box>
              </Grid>
              <Grid item xs={4}>
                <Box
                  alignItems="center"
                  display="flex"
                  height="100%"
                  justifyContent="center"
                >
                  <Box
                    alt="wordpress+peppercloud"
                    className={classes.img}
                    component="img"
                    height="88px !important"
                    src={Wordpress}
                  />
                </Box>
              </Grid>
              <Grid item xs={4}>
                <Box
                  alignItems="center"
                  display="flex"
                  height="100%"
                  justifyContent="center"
                >
                  <Box
                    alt="Weebly+peppercloud"
                    className={classes.img}
                    component="img"
                    height="120px !important"
                    src={Weebly}
                  />
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={6} sm={12} />
        </Grid>
      </Container>

      <Box my={{ md: 10 }}>
        <Divider light />
      </Box>

      <Container>
        <Grid container spacing={2}>
          <Grid item md={6} sm={12}>
            <HeaderTypography
              component="h2"
              my={4}
              textAlign={{ sm: "center", xs: "center" }[width] || "left"}
            >
              Design the way you want
            </HeaderTypography>
            <ParagraphTypography
              textAlign={{ sm: "center", xs: "center" }[width] || "left"}
            >
              Leverage predesigned templates or design your own eye-catching web
              forms to gather all the required information of your customers.
              Choose your brand colour; change the font, size, and style in sync
              with your brand guidelines; add a custom success message or
              redirect link, and map the data with any custom fields within the
              CRM tool.
            </ParagraphTypography>
          </Grid>

          <Grid item md={6} sm={12}>
            <img
              alt="Web form layout that can be customised by changing the colours, fonts, and size"
              className={classes.img}
              src={DesignTheWayYouWant}
            />
          </Grid>
        </Grid>
      </Container>

      <Box my={{ md: 10 }}>
        <Divider light />
      </Box>

      <Container>
        <Box alignItems="center" display="flex" flexDirection="column" pt={3}>
          <HeaderTypography
            component="h2"
            maxWidth={726}
            mb={4}
            mt={0}
            textAlign="center"
          >
            Close deals faster with insights
          </HeaderTypography>
          <ParagraphTypography maxWidth={860} mb={6} textAlign="center">
            Maintain constant insight into which web forms are performing well
            and how visitors are interacting with them. Refurbish the structure
            of your web forms to generate more leads. Identify the friction
            areas where the prospects are dropping off and rectify them. Move
            down the leads through the sales funnel and improve the quality of
            leads by improving your Web Form attributes.
          </ParagraphTypography>
        </Box>
        <img
          alt="Different types of web forms"
          className={classes.img}
          src={AnalyzeToImproveYourLeadGeneration}
        />
      </Container>

      <Box my={{ md: 8 }}>
        <Divider light />
      </Box>

      <Container>
        <Box alignItems="center" display="flex" flexDirection="column" pt={3}>
          <HeaderTypography
            component="h2"
            maxWidth={726}
            mb={4}
            mt={0}
            textAlign="center"
          >
            Lead generation and beyond
          </HeaderTypography>
        </Box>
        <Grid container justify="center" spacing={4}>
          <Grid component={Box} item md={4} sm={4} textAlign="center" xs={12}>
            <div className={classes.featureItem}>
              <Box
                alt="sales conversations"
                className={classes.img}
                component="img"
                src={Communicate}
                width={100}
              />
              <Box color="text.secondary" fontSize={20} fontWeight="600">
                Build contextual conversation with high-intent website visitors
                effectively.
              </Box>
            </div>
          </Grid>
          <Grid component={Box} item md={4} sm={4} textAlign="center" xs={12}>
            <div className={classes.featureItem}>
              <Box
                alt="High-intent lead capture"
                className={classes.img}
                component="img"
                src={Automate}
                width={100}
              />
              <Box color="text.secondary" fontSize={20} fontWeight="600">
                <a href="https://blog.peppercloud.com/lead-generation-web-forms-a-complete-guide/">
                  Automate the lead capture{" "}
                </a>{" "}
                and nurturing process with efficient lead generation CRM
                software.
              </Box>
            </div>
          </Grid>
          <Grid component={Box} item md={4} sm={4} textAlign="center" xs={12}>
            <div className={classes.featureItem}>
              <Box
                alt="Online form analysis"
                className={classes.img}
                component="img"
                src={Conduct}
                width={100}
              />
              <Box color="text.secondary" fontSize={20} fontWeight="600">
                Conduct surveys, boost registration and receive feedback through
                web forms.
              </Box>
            </div>
          </Grid>
        </Grid>
      </Container>

      <Box my={{ md: 8 }}>
        <Divider light />
      </Box>

      <Box component={Container} textAlign="center">
        <Box py={5}>
          <HeaderTypography
            alignItems="center"
            component="h2"
            display="flex"
            fontSize={40}
            justifyContent="center"
            lineHeight={1.2}
            mb={2}
            mt={0}
          >
            Here is what our happy customers are saying
          </HeaderTypography>
          <Box display="flex" justifyContent="center">
            <ParagraphTypography maxWidth={720}>
              Many businesses trust Pepper Cloud CRM software to empower their
              sales team and run their operations globally. Know about our
              clients&apos; experiences in their words.
            </ParagraphTypography>
          </Box>
        </Box>
        <Container>
          <SingleTestimonial {...client} />
        </Container>
      </Box>

      <Box className={classes.requestDemoRoot} mt={6} py={8}>
        <Container>
          <HeaderTypography
            color="common.white"
            fontWeight={600}
            fontSize={24}
            mt={0}
            textAlign="center"
          >
            Are you ready to take your step?
          </HeaderTypography>
          <Box display="flex" justifyContent="center" mt={6}>
            <WhiteButton
              color="primary"
              component={Link}
              size="large"
              to="/contact"
              variant="contained"
            >
              {/* eslint-disable-next-line react/no-unescaped-entities */}
              Let's do this!
            </WhiteButton>
          </Box>
        </Container>
      </Box>
    </React.Fragment>
  );
};

export default Webform;
